.hero {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;

  & .useful-links {
    z-index: 10;
  }
  
  &__bg-animation {
    height: 100vh;
    overflow-x: hidden;
    position: absolute;
    width: 97%;
    z-index: 1;
    
    & > svg {
      position: absolute;

      @media (max-width: 700px) {
        display: none;
      }
      
      & > line {
        animation: drawLine 3s linear forwards;
        stroke-dasharray: 100;
        stroke-dashoffset: 100;
      }
      
      @keyframes drawLine { 
        to {
          stroke-dashoffset: 0;
        }
      }
    }
  }
}
