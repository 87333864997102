@import "./scss/colours";
@import "./scss/type";

@import "./scss/components/hero";
@import "./scss/components/skills";

html,
body {
  background-color: $black;
  color: $white;
}

section {
  margin: 8px;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media (min-width: 700px) {
    align-items: unset;
    flex-direction: row;
    justify-content: space-evenly;
  }
}