.skills {
  border: 2px dashed $grey;
  list-style: none;
  margin: 8px;
  padding: 8px;
  width: 90%;

  @media (min-width: 700px) {
    width: 40%;
  }

  &--full-width {
    width: 87.5%;
  }
}

.skill {
  $score-map: (
    1: "Learning",
    2: "Competent",
    3: "Proficient",
    4: "Experienced",
    5: "Mastered"
  );
  $max-score: 5;
  $max-width: 80% / $max-score;

  display: flex;
  flex-direction: column;
  font-weight: bold;
  margin-top: 32px;
  width: 100%;

  &:last-of-type {
    margin-bottom: 32px;
  }

  &__title {
    padding: 0 8px;
  }

  &__text {
    font-weight: normal;
    padding: 4px 8px;

    &:last-of-type {
      margin-bottom: 16px;
    }
  }

  // TODO: Make this animate when scrolled into view
  &__display {
    @for $i from 1 through $max-score {
      &[score="#{$i}"] {
        align-items: center;
        animation: skillDisplayAnimation-#{$i} 1s;
        background-color: $white;
        display: flex;
        height: 24px;
        margin-top: 16px;
        width: $max-width * $i;

        &::after {
          color: $white;
          content: map-get($map: $score-map, $key: $i);
          font-size: 0.9em;
          font-weight: normal;
          margin-left: 105%;
        }

        @keyframes skillDisplayAnimation-#{$i} {
          from {
            width: 0px;
          }

          to {
            width: $max-width * $i;
          }
        }
      }
    }
  }
}
